import React, { Component } from 'react';
import { Icon } from "monday-ui-react-core";
import { IconButton } from "monday-ui-react-core";
import { Search } from "monday-ui-react-core";
import { Flex } from "monday-ui-react-core";
import { Tooltip } from "monday-ui-react-core";
import { Delete, Deactivate } from "monday-ui-react-core/icons";
import { Download } from "monday-ui-react-core/icons";
import { Warning } from "monday-ui-react-core/icons";
import { Info } from "monday-ui-react-core/icons";
import { downloadCreatedDocument } from './fileDownloader';
import axios from 'axios';
import DOMPurify from 'dompurify';
import mondaySdk from "monday-sdk-js";
import { Table, TableCell, TableBody, TableHeader, TableHeaderCell, TableRow } from "monday-ui-react-core";

const TableEmptyState = () => <h1 style={{ textAlign: "center" }}>Empty State</h1>;

const TableErrorState = () => <h1 style={{ textAlign: "center" }}>Error State</h1>;

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class Documents extends Component {
    static displayName = Documents.name;

    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            loading: true,
            selectedPdfUrl: null,
            isBoardOwner: props.isBoardOwner
        };
        this.mondayContext = window.mondayContext;
        this.downloadDocument = this.downloadDocument.bind(this);
        this.previewDocument = this.previewDocument.bind(this);
        this.rowClick = this.rowClick.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.handleCreateNewDocument = this.handleCreateNewDocument.bind(this);
        this.handleRevokeSignature = this.handleRevokeSignature.bind(this);
    }

    componentDidMount() {
        this.populateDocumentData();
    }

    handleCreateNewDocument() {
        this.props.onCreateNewDocument();
        // I don't know how to make this work -> I found no way to change the activeTabId programmatically, see C:\Temp\App Tabs CreateNewDocument Button.js
        // See also https://style.monday.com/?path=/docs/navigation-tabs-tabs--docs
    }

    async downloadDocument(document) {
        await downloadCreatedDocument(document);
    //    var url = '/document/Download?ForViewer=true&Id=' + document.id + '&SessionToken=' + window.mondaySessionToken;
    //    console.log(url);
    //    this.setState({ selectedPdfUrl: url });
    }

    handleRevokeSignature(document) {
        monday.execute("confirm", {
            message: 'Do you really want to revoke the signature requests for this document?',
            confirmButton: "Revoke",
            cancelButton: "Cancel",
            excludeCancelButton: false
        }).then(async (res) => {
            if (res.data.confirm) {
                try {
                    await axios.post('document/RevokeSignatureRequest?Id=' + document.id + '&SessionToken=' + window.mondaySessionToken);                    
                    alert('Please wait a few seconds for the signature requests to be revoked. Then refresh the page to see the updated status.');
                    await this.populateDocumentData();
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        });
    }

    async previewDocument(document) {
        //console.log('Preview document', document);
        if (document.itemId && document.columnId && document.assetId) {
            monday.execute("openFilesDialog", {
                boardId: this.mondayContext.boardId,
                itemId: document.itemId,
                columnId: document.columnId,
                assetId: document.assetId
            });
        }        
    }

    rowClick(document) {
        var url = '/document/Download?ForViewer=true&Id=' + document.id + '&SessionToken=' + window.mondaySessionToken;
        console.log(url);
        this.setState({ selectedPdfUrl: url });
    }

    renderNewTable(documents) {

        var columns = [{
            id: "download",
            title: "",
            width: 40,
        }, {
            id: "documentType",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }, {
            id: "name",
            title: "Name",
            width: {
                min: 120,
                max: 500
            },
            loadingStateType: "long-text"
        }, {
            id: "itemName",
            title: "Item",
            width: {
                min: 120,
                max: 500
            },
            loadingStateType: "long-text"
        }, {
            id: "userName",
            title: "Created by",
            width: {
                min: 120,
                max: 200
            },
            loadingStateType: "medium-text"
        }, {
            id: "createdOn",
            title: "Created on",
            width: 160,
            loadingStateType: "medium-text"
        }, {
            id: "delete",
            title: "",
            width: 40,
            loadingStateType: "circle"
        }];

        var tableDataState = {
            isLoading: this.state.loading
        };

        return (
            <Table errorState={<TableErrorState />} emptyState={<TableEmptyState />} className="app-spirit-document-table" columns={columns} dataState={tableDataState}>
                <TableHeader>
                    {columns.map((headerCell, index) => <TableHeaderCell key={index} title={headerCell.title} />)}
                </TableHeader>
                <TableBody>
                    {documents.map(rowItem =>
                        <Tooltip key={rowItem.id} content={rowItem.toolTip}>
                            <TableRow key={rowItem.id}>
                                <TableCell className="app-spirit-tablecell-with-button" >
                                    <IconButton
                                        ariaLabel="Download"
                                        size={IconButton.sizes.SMALL}
                                        icon={Download}
                                        onClick={() => this.downloadDocument(rowItem)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <img
                                        className="app-spirit-img-in-tablecell"
                                        src={`/${rowItem.documentType}.png`}
                                        onClick={() => this.previewDocument(rowItem)}
                                        alt={rowItem.documentType} />
                                </TableCell>
                                <TableCell className="app-spirit-tablecell-with-warning-icon" onClick={() => console.log('Row clicked', rowItem)}>
                                    {rowItem.name}
                                    {rowItem.iconName === "Warning" && <Icon className="app-spirit-icon-warning-in-tablecell" icon={Warning} />}
                                    {rowItem.iconName === "Info" && <Icon className="app-spirit-icon-warning-in-tablecell" icon={Info} />}
                                    {rowItem.signatureState && <>&nbsp;({rowItem.signatureState})</>}
                                </TableCell>
                                <TableCell>{rowItem.itemName}</TableCell>
                                <TableCell>{rowItem.userName}</TableCell>
                                <TableCell>{rowItem.createdOn}</TableCell>
                                <TableCell className="app-spirit-tablecell-with-button" >
                                    {this.state.isBoardOwner && rowItem.signatureState === 'InProgress' && (
                                        <IconButton
                                            ariaLabel="Revoke signature request"
                                            size={IconButton.sizes.SMALL}
                                            icon={Deactivate}
                                            onClick={(e) => this.handleRevokeSignature(rowItem)}
                                        />
                                    )}
                                    {this.state.isBoardOwner && rowItem.signatureState !== 'InProgress' && (
                                        <IconButton
                                            ariaLabel="Delete"
                                            size={IconButton.sizes.SMALL}
                                            icon={Delete}
                                            onClick={(e) => this.deleteDocument(e, rowItem.id)}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        </Tooltip>)}
                </TableBody>
            </Table>
        );
    }

    async deleteDocument(event, id) {
        //console.log('Delete document ' + id);

        let message = "Do you really want to delete the document? It still counts towards your monthly limit.";
        if (this.props.isReportGenerator)
            message = "Do you really want to delete the report? It still counts towards your monthly limit.";

        // https://developer.monday.com/apps/docs/mondayexecute#confirm
        monday.execute("confirm", {
            message: message,
            confirmButton: "Delete",
            cancelButton: "Cancel",
            excludeCancelButton: false
        }).then(async (res) => {
            console.log(res.data);
            if (res.data.confirm) {
                try {
                    const response = await axios.delete('document?Id=' + id + '&SessionToken=' + window.mondaySessionToken);
                    console.log('Success:', response.data);
                    await this.populateDocumentData();
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        });
    }

    handleSearchChange(text) {
        this.searchText = text;
        this.populateDocumentData();
    }

    render() {
        let contents = this.renderNewTable(this.state.documents);        
        const title = this.props.isReportGenerator ? "Reports" : "Documents";
        let noDocumentsHint = null;
        if (this.state.documents.length === 0 && !this.state.loading) {
            if (this.props.isReportGenerator)
                noDocumentsHint = <p>No reports found. Please go to the "Generate Report" tab to generate your first report!</p>;
            else
                noDocumentsHint = <p>No documents found. Please go to the "Generate Document" tab to generate your first document!</p>;
        }

        return (
            <div>
                <Flex>
                    <h3 id="tableLabel">{title}</h3>
                    <Search wrapperClassName="app-spirit-search" size={Search.sizes.SMALL} onChange={this.handleSearchChange} placeholder="Search" />
                </Flex>
                {/*<Button onClick={this.handleCreateNewDocument}>Create new</Button>*/}
                {/*<p></p>*/}
                {contents}
                {noDocumentsHint}
            </div>
        );
    }

    renderPdfIframe() {
        const { selectedPdfUrl } = this.state;

        if (!selectedPdfUrl) {
            return null;
        }

        return (
            <iframe
                src={selectedPdfUrl}
                width="100%"
                height="600px"
                style={{ border: 'none' }}
                title="PDF Viewer"
            />
        );
    }

    async populateDocumentData() {
        var timeZoneOffset = this.mondayContext.user.timeZoneOffset;
        if (timeZoneOffset === undefined)
            timeZoneOffset = 0;
        var searchQuery = '';
        if (this.searchText)
            searchQuery = '&SearchText=' + DOMPurify.sanitize(this.searchText);
        const response = await fetch('document/list?BoardId=' + this.mondayContext.boardId +
            '&UserCountryCode=' + DOMPurify.sanitize(this.mondayContext.user.countryCode) +
            '&UserLanguageCode=' + DOMPurify.sanitize(this.mondayContext.user.currentLanguage) +
            '&TimeZoneOffset=' + timeZoneOffset +
            '&SessionToken=' + window.mondaySessionToken +
            searchQuery);
        const data = await response.json();
        this.setState({ documents: data, loading: false });
    }
}
