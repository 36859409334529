import React, { Component } from 'react';
import { ExpandCollapse } from "monday-ui-react-core";
import { Button, Flex, Dropdown, MenuButton, Menu, MenuItem, IconButton } from "monday-ui-react-core";
import { EditableHeading, Tooltip } from "monday-ui-react-core";
import { Checkbox, TextField } from "monday-ui-react-core";
import { Info } from "monday-ui-react-core/icons";
import mondaySdk from "monday-sdk-js";
import axios from 'axios';
import DOMPurify from 'dompurify';
import { convertMondayFilterToGraphQLFilter } from './MondayFilterHelper';

const monday = mondaySdk();
monday.setApiVersion("2023-10");

export class TableDefinitionEdit extends Component {
    static displayName = TableDefinitionEdit.name;

    constructor(props) {
        super(props);

        this.defaultColumnForOrderBy = { value: null, label: 'Order in board' };
        this.defaultColumnForGroupBy = { value: 'Group', label: 'Group' };
        this.columnsForBoards = {};
        this.state = {
            tableName: 'Table name',
            loading: true,            
            isRenderingPreview: false,
            placeholderName: null,
            isSaving: false,
            useFilter: true,
            mondayFilterText: null,
            selectedOrderByColumn: this.defaultColumnForOrderBy,
            selectedGroupByColumn: this.defaultColumnForGroupBy,
            dataSources: [],
            selectedDataSource: null,
            groups: [],
            selectedGroup: null,
            checkedColumns: [],    // TODO: Per dataSource??
            lastColumnsLoaded: null,
            manualFilterText: '',
            manualFilterName: '',
            footerMode: '',
            conditionStatusValues: null,
            conditionStatusColumnId: null,
            conditionStatusColumnName: null
        };
        this.titleTimeoutId = null;
        this.needsPreviewUpdate = false;
        this.mondayFilterText = null;
        this.isRenderingPreview = false;        
        this.mondayContext = window.mondayContext;
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleTableNameChange = this.handleTableNameChange.bind(this);
        this.handleUseFilterCheckboxChange = this.handleUseFilterCheckboxChange.bind(this);
        this.handleOrderByColumnChange = this.handleOrderByColumnChange.bind(this);
        this.handleGroupByColumnChange = this.handleGroupByColumnChange.bind(this);
        this.handleColumnCheckboxChange = this.handleColumnCheckboxChange.bind(this);
        this.handleDataSourceChange = this.handleDataSourceChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleManualFilterTextChange = this.handleManualFilterTextChange.bind(this);
        this.conditionStateValueSelected = this.conditionStateValueSelected.bind(this);
        this.conditionOpenHelp = this.conditionOpenHelp.bind(this);       
        this.handleFooterModeCheckboxChange = this.handleFooterModeCheckboxChange.bind(this);
    }

    conditionOpenHelp() {
        const urlPath = "https://developer.monday.com/api-reference/reference/items-page#use-cases";
        monday.execute("openLinkInTab", { url: urlPath });
    }

    conditionStateValueSelected(key, displayText) {
        var filterText = `[{column_id:"${this.state.conditionStatusColumnId}",compare_value:[${key}],compare_attribute:"",operator:any_of}]`;
        this.setState({ manualFilterText: filterText, manualFilterName: displayText });
    }

    handleManualFilterTextChange(text) {
        this.setState({ manualFilterText: text });
    }

    getColumnSettings(column) {
        if (column.settings)
            return column.settings;
        if (!column.settings_str)
            return undefined;
        var settings;
        try {
            // Parse the settings_str JSON string
            settings = JSON.parse(column.settings_str);
            column.settings = settings;
        } catch (error) {
            console.error("Error parsing settings string:", error);
            return;
        }
        return settings;
    }

    isDataSource(dataSource) {
        if (!this.props.dataSource)
            return true;
        if (this.props.dataSource === dataSource)
            return true;
        return false;
    }

    updateDataSources() {
        var dataSources = [];
        if (this.isDataSource('Board')) {
            dataSources.push({ value: 'Items', label: 'Items' });
            dataSources.push({ value: 'OneGroup', label: 'Items in one group' });
        }
        if (this.isDataSource('SingleItem')) {
            if (this.props.columns)
                if (this.props.columns.some(c => c.type === 'subtasks'))
                    dataSources.push({ value: 'SubItems', label: 'Subitems' });

            //dataSources.push({ value: 'BR_test', label: 'BR_test' });

            var boardRelationColumns = this.props.columns.filter(c => c.type === 'board_relation');
            boardRelationColumns.forEach(column => {
                const settingsObj = this.getColumnSettings(column);
                if (!settingsObj)
                    return;
                const boardId = settingsObj.boardId;
                if (!boardId)
                    return;
                dataSources.push({ value: 'BR_' + column.id, label: column.title, boardId: boardId });
            });
        }

        var selectedDataSource = this.state.selectedDataSource;
        if (!selectedDataSource)
            selectedDataSource = dataSources[0];
        this.setState({ dataSources: dataSources, selectedDataSource: selectedDataSource });        
    }

    componentDidMount() {
        this.updateDataSources();
        this.loadColumnData();

        monday.listen('filter', res => {
            //console.log(res);
            var mondayFilterText = null;
            if (res.data.rules && res.data.rules.length > 0) {
                mondayFilterText = convertMondayFilterToGraphQLFilter(res.data.rules);
            }
            if (mondayFilterText !== this.mondayFilterText) {
                this.setState({ mondayFilterText: mondayFilterText });
                this.mondayFilterText = mondayFilterText;
            //    if (this.state.useFilter)
            //        this.updateChartPreviewAsync();
            }
        });
    }

    handleUseFilterCheckboxChange() {
        this.setState({ useFilter: !this.state.useFilter });        
    }

    handleFooterModeCheckboxChange() {
        var footerMode = this.state.footerMode === 'Sum' ? '' : 'Sum';
        this.setState({ footerMode: footerMode });        
    }

    handleTableNameChange(text) {
        this.setState({ tableName: text });
    }

    handleCancelClick() {
        this.props.onClose();
    }

    async handleClickSave() {
        this.setState({ isSaving: true });
        const data = this.getDataForPOST();
        try {
            const response = await axios.post('tabledefinition', data);
            //console.log(response.data);
            if (response.data.result) {                
                //if (response.data.isAlreadyExisting) {
                //    monday.execute("notice", {
                //        message: 'This table definition already exists with name ' + response.data.tableDefinition.name,
                //        type: "info", // or "error" (red), or "info" (blue)
                //        timeout: 5000,
                //    });
                //}
                this.setState({ isSaving: false });
                this.props.onClose(response.data.tableDefinition, response.data.isAlreadyExisting);
            } else {
                var errorMessage = 'Error saving table: ' + response.data.error;
                console.log(errorMessage);
                alert(errorMessage);
                this.setState({ isSaving: false });
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error);
            this.setState({ isSaving: false });
        }
    }

    loadColumnData() {
        if (!this.mondayContext.connected) {
            this.mondayColumnData = {
                boards: [{
                    id: '1',
                    name: 'Board name',
                    columns: [
                        { id: 'status_11', title: 'Status', type: 'status' },
                        { id: 'date4', title: 'Payment due', type: 'date' },
                        { id: 'subitems_amount', title: 'Subtotal', type: 'mirror' },
                        { id: 'numbers_15', title: 'Amount', type: 'numbers' }],
                    groups: [
                        { id: '1', color: 'blue', title: 'Group 1' },
                        { id: '2', color: 'green', title: 'Group 2' },
                    ]
                }]
            };
            this.setColumnsAndGroups(this.mondayColumnData.boards[0]);

            return;
        }
        monday.api(this.GetColumnQuery(this.mondayContext.boardId), { apiVersion: '2023-10' }).then(columnData => {
            //console.log(columnData);
            this.mondayColumnData = columnData.data;
            this.setColumnsAndGroups(this.mondayColumnData.boards[0]);
        });
    }

    getColumnsForBoard(boardId) {
        if (!boardId)
            return [];
        var result = this.columnsForBoards[boardId];
        if (result)
            return result.columns;
        //console.log('Loading ' + boardId);
        monday.api(this.GetColumnQuery(boardId), { apiVersion: '2023-10' }).then(columnData => {            
            this.columnsForBoards[boardId] = columnData.data.boards[0];
            this.setState({ lastColumnsLoaded: Date.now() });
            this.resetCheckedColumns();
        });
        return [];  // Will be updated after loading the columns
    }

    getColumnsForDataSource() {
        if (!this.state.selectedDataSource)
            return [];
        var columns = this.props.columns;
        if (this.state.selectedDataSource?.value === 'SubItems')
            columns = this.props.subItemsColumns;
        if (this.state.selectedDataSource?.value.startsWith('BR_')) {
            //var boardRelationColumnId = this.state.selectedDataSource.value.substring(3);
            var boardId = this.state.selectedDataSource.boardId;            
            columns = this.getColumnsForBoard(boardId);
        }
        return columns;
    }

    getColumnsForChecklist() {
        var columns = this.getColumnsForDataSource();
        let result = columns.filter(c => c.type !== 'subtasks' && c.type !== 'button');
        //if (this.props.isReportGenerator)
        //    result = result.filter(c => c.type !== 'file');
        return result;
    }

    resetCheckedColumns() {
        var allColumns = this.getColumnsForChecklist();

        var checkedColumns = [];
        for (let i = 0; i < allColumns.length && i < 5; i++) {
            checkedColumns.push(allColumns[i].id);
        }
        this.setState({ checkedColumns: checkedColumns });

        if (this.state.selectedDataSource?.value.startsWith('BR_') || this.state.selectedDataSource?.value === 'SubItems') {
            var statusColumn = allColumns.find(c => c.type === 'status');
            if (statusColumn) {
                const settingsObj = this.getColumnSettings(statusColumn);
                //console.log(settingsObj.labels);
                //console.log(Object.entries(settingsObj.labels));
                this.setState({
                    conditionStatusValues: Object.entries(settingsObj.labels),
                    conditionStatusColumnName: statusColumn.title,
                    conditionStatusColumnId: statusColumn.id
                });
            }
        }
    }

    setColumnsAndGroups(board) {
        //var allColumns = this.getColumnsForChecklist();

        //var checkedColumns = [];
        //for (let i = 0; i < allColumns.length && i < 5; i++) {
        //    checkedColumns.push(allColumns[i].id);
        //}
        //console.log(checkedColumns);

        this.resetCheckedColumns();

        this.setState({            
            groups: board.groups.map(g => {
                return { value: g.id, label: g.title };
            }),
            loading: false,
            //checkedColumns: checkedColumns
        });
        this.updateDataSources();
    }

    GetColumnQuery(boardId) {
        return `
query {
  complexity {
    query
    after
  }
  boards (ids: ${boardId}) { 
    id      
    name
    item_terminology
    groups {
      id
      color
      title
    }
    columns {
        id
        title
        type
        width
        settings_str
    }
  }
        }`;
    }

    getDataForPOST() {
        const data = {            
            accountId: this.mondayContext.account?.id,
            boardId: this.mondayContext.boardId,
            workspaceId: this.mondayContext.workspaceId,
            userCountryCode: this.mondayContext.user.countryCode,
            userLanguage: this.mondayContext.user.currentLanguage,
            sessionToken: window.mondaySessionToken,
            name: DOMPurify.sanitize(this.state.tableName),
            itemsOrderBy: DOMPurify.sanitize(this.state.selectedOrderByColumn?.value),
            itemsOrderByName: DOMPurify.sanitize(this.state.selectedOrderByColumn?.label),
            groupBy: DOMPurify.sanitize(this.state.selectedGroupByColumn?.value),
            groupByName: DOMPurify.sanitize(this.state.selectedGroupByColumn?.label),
            groupByType: DOMPurify.sanitize(this.state.selectedGroupByColumn?.type),
            columns: this.getColumnsForChecklist().filter(c => this.state.checkedColumns.includes(c.id)),
            dataSource: DOMPurify.sanitize(this.state.selectedDataSource?.value),
            groupId: DOMPurify.sanitize(this.state.selectedGroup?.value),
            groupName: DOMPurify.sanitize(this.state.selectedGroup?.label),
            itemTerminology: DOMPurify.sanitize(this.props.columns.find(c => c.type === 'name')?.item_terminology),
            footerMode: DOMPurify.sanitize(this.state.footerMode),
        };
        if (this.state.useFilter && this.state.mondayFilterText) {
            data.itemsFilter = this.state.mondayFilterText;
        }
        if (this.state.selectedDataSource?.value.startsWith('BR_')) {
            data.boardRelationId = DOMPurify.sanitize(this.state.selectedDataSource.value.substring(3));
            data.boardRelationName = DOMPurify.sanitize(this.state.selectedDataSource.label);
            data.boardRelationBoardId = DOMPurify.sanitize(this.state.selectedDataSource.boardId);
            data.itemsFilter = DOMPurify.sanitize(this.state.manualFilterText);
            data.itemsFilterName = DOMPurify.sanitize(this.state.manualFilterName);
        }
        if (this.state.selectedDataSource?.value === 'SubItems') {
            data.itemsFilter = DOMPurify.sanitize(this.state.manualFilterText);
            data.itemsFilterName = DOMPurify.sanitize(this.state.manualFilterName);
        }
        return data;
    }

    getColumnsForOrderBy() {
        var columns = this.getColumnsForDataSource().filter(c => c.type !== 'mirror' && c.type !== 'subtasks');
        var options = columns.map(column => {
            return { value: column.id, label: column.title };
        });
        options.unshift(this.defaultColumnForOrderBy);
        return options;
    }

    getColumnsForGroupBy() {
        var columns = this.getColumnsForDataSource().filter(c => c.type === 'status' || c.type === 'people' || c.type === 'text');
        var options = columns.map(column => {
            return { value: column.id, label: column.title, type: column.type };
        });
        options.unshift(this.defaultColumnForGroupBy);
        options.unshift({ value: null, label: 'None' });
        return options;
    }    

    handleOrderByColumnChange(selectedOption) {
        this.setState({ selectedOrderByColumn: selectedOption });
    }

    handleGroupByColumnChange(selectedOption) {
        this.setState({ selectedGroupByColumn: selectedOption });
    }

    handleDataSourceChange(selectedOption) {
        if (this.state.selectedDataSource)
            if (selectedOption)
                if (this.state.selectedDataSource.value === selectedOption.value)
                    return;
        this.setState({ selectedDataSource: selectedOption }, () => { this.resetCheckedColumns(); });
    }

    handleGroupChange(selectedOption) {
        this.setState({ selectedGroup: selectedOption });
    }

    handleColumnCheckboxChange(column) {
        var newCheckedColumns;
        if (this.state.checkedColumns.includes(column.id))
            newCheckedColumns = this.state.checkedColumns.filter(c => c !== column.id);
        else
            newCheckedColumns = [...this.state.checkedColumns, column.id];
        this.setState({ checkedColumns: newCheckedColumns });
    }

    cannotSave() {
        if (!this.state.selectedDataSource)
            return true;
        if (this.state.selectedDataSource?.value === 'OneGroup')
            if (!this.state.selectedGroup)
                return true;
        if (this.state.checkedColumns.length === 0)
            return true;
        return false;
    }
    
    render() {
        if (this.state.loading)  // TODO: Loading animation
            return (<p><em>Loading...</em></p>);

        const shouldRenderApplyFilterCheckbox = this.state.mondayFilterText;
        const filterConditionPlaceholderText = 'example: [{column_id:"status",compare_value:[0],compare_attribute:"",operator:any_of}]';
        
        return (
            <div>
                <Tooltip content="Click to Edit">
                    <EditableHeading
                        className="app-spirit-little-space-bottom"
                        onChange={this.handleTableNameChange}
                        type="h2"
                        value={this.state.tableName}
                    />
                </Tooltip>
                <div className="app-spirit-table-settings-column">
                    <Flex>
                        <div className="app-spirit-label">Data source</div>
                        <Dropdown
                            className="app-spirit-dropdown"
                            size={Dropdown.sizes.SMALL}
                            onBlur={function noRefCheck() { }}
                            onChange={this.handleDataSourceChange}
                            onClear={function noRefCheck() { }}
                            onFocus={function noRefCheck() { }}
                            onInputChange={function noRefCheck() { }}
                            onMenuClose={function noRefCheck() { }}
                            onMenuOpen={function noRefCheck() { }}
                            onOptionRemove={function noRefCheck() { }}
                            onOptionSelect={function noRefCheck() { }}
                            openMenuOnFocus={function noRefCheck() { }}
                            value={this.state.selectedDataSource}
                            options={this.state.dataSources}
                            clearable={false}
                            placeholder="Select a data source"
                        />
                        {this.state.selectedDataSource?.value === 'OneGroup' && (
                            <Dropdown
                                className="app-spirit-dropdown"
                                size={Dropdown.sizes.SMALL}
                                onBlur={function noRefCheck() { }}
                                onChange={this.handleGroupChange}
                                onClear={function noRefCheck() { }}
                                onFocus={function noRefCheck() { }}
                                onInputChange={function noRefCheck() { }}
                                onMenuClose={function noRefCheck() { }}
                                onMenuOpen={function noRefCheck() { }}
                                onOptionRemove={function noRefCheck() { }}
                                onOptionSelect={function noRefCheck() { }}
                                openMenuOnFocus={function noRefCheck() { }}
                                value={this.state.selectedGroup}
                                options={this.state.groups}
                                clearable={false}
                                placeholder="Select a group"
                            />
                        )}
                        {this.state.selectedDataSource?.value === 'Items' && (
                            <Tooltip content="Use board filters to narrow down the items you want to be included in your documents. Click on 'Filter' in the header bar of this board view to select and save your filter settings.">
                                <Checkbox onChange={this.handleUseFilterCheckboxChange}
                                    disabled={!shouldRenderApplyFilterCheckbox}
                                    checked={shouldRenderApplyFilterCheckbox && this.state.useFilter}
                                    label="Apply board filter" />
                            </Tooltip>
                        )}
                    </Flex>
                    <br />
                    {this.state.selectedDataSource?.value === 'Items' && (
                        <div>
                            <Flex>
                                <div className="app-spirit-label">Group by</div>
                                <Dropdown
                                    className="app-spirit-dropdown"
                                    size={Dropdown.sizes.SMALL}
                                    onBlur={function noRefCheck() { }}
                                    onChange={this.handleGroupByColumnChange}
                                    onClear={function noRefCheck() { }}
                                    onFocus={function noRefCheck() { }}
                                    onInputChange={function noRefCheck() { }}
                                    onMenuClose={function noRefCheck() { }}
                                    onMenuOpen={function noRefCheck() { }}
                                    onOptionRemove={function noRefCheck() { }}
                                    onOptionSelect={function noRefCheck() { }}
                                    openMenuOnFocus={function noRefCheck() { }}
                                    value={this.state.selectedGroupByColumn}
                                    options={this.getColumnsForGroupBy()}
                                    clearable={false}
                                    placeholder="Select a column"
                                />
                                <Tooltip content="Add a row at the bottom of the table containing the sums of the columns.">
                                    <Checkbox onChange={this.handleFooterModeCheckboxChange}
                                        checked={this.state.footerMode === 'Sum'}
                                        label="Display sums for each group" />
                                </Tooltip>
                            </Flex>
                        </div>)}
                    <p>Columns</p>
                    {this.getColumnsForChecklist().map(c =>
                        <Checkbox key={c.id} onChange={(e) => this.handleColumnCheckboxChange(c)} checked={this.state.checkedColumns.includes(c.id)}
                            label={c.title}
                        />
                    )}
                    {this.state.checkedColumns.length > 8 && <p><em>Hint: For optimal results, please choose a maximum of 10 columns.</em></p>}
                    <p></p>
                    {this.state.selectedDataSource?.value !== 'OneGroup' && <ExpandCollapse title="Advanced settings">
                        {(this.state.selectedDataSource?.value.startsWith('BR_') || this.state.selectedDataSource?.value === 'SubItems') && (
                            <div className="app-spirit-textbox-margin-top">
                                <span>Filter condition</span>
                                <Flex>
                                    <TextField
                                        placeholder={filterConditionPlaceholderText}
                                        value={this.state.manualFilterText}
                                        onChange={this.handleManualFilterTextChange} />
                                    {this.state.conditionStatusValues && (<MenuButton
                                        closeMenuOnItemClick
                                        tooltipContent="Set filter condition based on status"
                                    >
                                        <Menu
                                            id="menu"
                                            size="medium"
                                        >
                                            {this.state.conditionStatusValues?.map(([key, label]) => (
                                                <MenuItem
                                                    key={key}
                                                    onClick={(e) => this.conditionStateValueSelected(key, this.state.conditionStatusColumnName + " " + label)}
                                                    title={this.state.conditionStatusColumnName + " = " + label}
                                                />))}
                                        </Menu>
                                    </MenuButton>)}
                                    <IconButton icon={Info} tooltipContent="Help me with filters" size={IconButton.sizes.SMALL} onClick={this.conditionOpenHelp} />
                                </Flex>
                                <p></p>
                            </div>                            
                        )}
                        {(this.state.selectedDataSource?.value === 'Items' || this.state.selectedDataSource?.value === 'SubItems') && (
                            <div>
                                <Flex className="app-spirit-little-space-bottom">
                                    <div className="app-spirit-label">Sort by</div>
                                    <Dropdown
                                        className="app-spirit-dropdown"
                                        size={Dropdown.sizes.SMALL}
                                        onBlur={function noRefCheck() { }}
                                        onChange={this.handleOrderByColumnChange}
                                        onClear={function noRefCheck() { }}
                                        onFocus={function noRefCheck() { }}
                                        onInputChange={function noRefCheck() { }}
                                        onMenuClose={function noRefCheck() { }}
                                        onMenuOpen={function noRefCheck() { }}
                                        onOptionRemove={function noRefCheck() { }}
                                        onOptionSelect={function noRefCheck() { }}
                                        openMenuOnFocus={function noRefCheck() { }}
                                        value={this.state.selectedOrderByColumn}
                                        options={this.getColumnsForOrderBy()}
                                        clearable={false}
                                        placeholder="Select a column"
                                    />
                                </Flex>
                            </div>
                        )}
                    </ExpandCollapse>}
                    <p></p>
                    <Button loading={this.state.isSaving} disabled={this.cannotSave()} onClick={this.handleClickSave}>
                        Save
                    </Button>
                    <Button kind={Button.kinds.SECONDARY} className="app-spirit-cancelbutton" onClick={this.handleCancelClick}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}